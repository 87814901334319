import"../styles/main.scss";

import gsap from"gsap";
import ScrollTrigger from"gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);


gsap.defaults({ ease: "ease" });

gsap.config({
    nullTargetWarn: false,
    force3D: false,
    autoSleep: 60
});

// import imagesLoaded from "imagesloaded";
import FontFaceObserver from"fontfaceobserver";
const fonts = ["Karelia-regular", "Gotham-book", "Gotham-bold"];

// export { gsap, $ };

window.addEventListener("DOMContentLoaded", runLoader);

function runLoader(){
    initScripts();

    fonts.forEach((f, i) => fonts[i] = new FontFaceObserver(f).load(null, 4000));

    Promise.all(fonts).then(function (){
        // imagesLoaded(document.documentElement).on("done", afterPageLoaded);
        afterPageLoaded();

    }, function (){
        console.log("Unable to load font initially");
        afterPageLoaded();
    });

    preventAutoScroll();
}


function preventAutoScroll(): void{
    const observer = new MutationObserver(() => {
        const banner = document.querySelector<HTMLElement>("#usercentrics-root"); // Replace with actual ID or class of the cookie banner
        if(banner){

            banner.blur();
            window.scrollTo(0, 0);
            document.body.classList.add("no-overflow");

            const removeNoOverflow = () => {
                document.body.classList.remove("no-overflow");
            };

            banner.addEventListener("load", removeNoOverflow);

            setTimeout(removeNoOverflow, 1000);

            observer.disconnect();
        }
    });

    // Start observing the DOM for changes
    observer.observe(document.body, { childList: true, subtree: true });
}



function afterPageLoaded(){
    import("./base/fade-in").then((imp) => imp.default());

    // These are the global page animations- hence the delay from the page fade in
    setTimeout(() => { runScript("./base/scroll"); }, 500);
}

function runScript(path: string){
    import(`${path}`).then((imp) => imp.default());
}

function ifModuleImport(el: string, path: string){
    if(document.querySelector(el)){
        import(`./modules/${path}`).then((imp) => imp.default());
    }
}

function initScripts(){
    // Import global scripts
    runScript("./base/header");
    runScript("./base/misc");

    // Import module specific file if there is an element on the page
    if(!("ontouchstart" in document.documentElement)){
        ifModuleImport(".dual-cta", "dualcta");
    }
    ifModuleImport(".slider", "slider");
    ifModuleImport(".timeline", "timeline");
    ifModuleImport(".downloads", "downloads");
    ifModuleImport(".cycle", "cycle");
    ifModuleImport(".focus", "focus");
    ifModuleImport(".focus__pin__img__cta", "focus-cta");
    ifModuleImport(".count-up", "count-up");
    ifModuleImport(".video, .detail__content__video", "videos");
    ifModuleImport(".it-slider", "it-slider");
    ifModuleImport(".detail__showcase", "showcase");
    ifModuleImport(".listing", "listing");
    ifModuleImport(".load-more-results", "load-more");
    ifModuleImport(".contact__map", "contact-map");
    ifModuleImport(".post-type-archive-location", "cpt-location");
    ifModuleImport(".post-type-archive-downloads", "cpt-download");
}